import React, { useEffect, useState } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import ReactSelect from 'react-select';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import api from '../../../api';
import DatePicker from 'react-datepicker';
interface WeeklyProps {
  selectDate?: any;
  closeModal: () => void;
  cycle?: any;
  clients?: any;
  integration?: any;
}
const statusOptions = [
  { value: 'red', label: 'Red' },
  { value: 'amber', label: 'Amber' },
  { value: 'green', label: 'Green' },
];
const cycleOptions = [
  { value: 'all', label: 'All Cycles' },
  { value: 'Cycle 1', label: 'Cycle 1' },
  { value: 'Cycle 2', label: 'Cycle 2' },
];

const clientsOptions = [
  { value: 'all', label: 'All Clients' },
  { value: 'my', label: 'My Client' },
];

const integrationOptions = [
  { value: 'all', label: 'Integrated' },
  { value: 'missing_performance', label: 'Missing Performance' },
  { value: 'missing_integration', label: 'Missing Integration' },
  // { value: 'integrated', label: 'Integrated' },
];
const ExportModal: React.FC<WeeklyProps> = ({
  selectDate,
  closeModal,
  cycle,
  clients,
  integration,
}) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [processedData, setProcessedData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const lastMonth = moment().subtract(1, 'month').format('MM-yyyy');
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();

  const today = new Date();
  today.setMonth(today.getMonth() - 1);

  const [date, setDate] = useState(today);
  const [status, setStatus] = useState<{
    value: string;
    label: string;
  } | null>(statusOptions[0]);
  const [selectedCycle, setSelectedCycle] = useState<{
    label: string;
    value: string;
  } | null>(cycle ? cycle : cycleOptions[0]);

  const [selectedIntegration, setSelectedIntegration] = useState<{
    label: string;
    value: string;
  } | null>(integration ? integration : integrationOptions[0]);
  const [selectedClients, setSelectedClients] = useState<{
    label: string;
    value: string;
  } | null>(clients ? clients : clientsOptions[0]);

  const [loading, setLoading] = useState(false);
  const [downloadReady, setDownloadReady] = useState(false);

  // useEffect(() => {
  //   fetchExportData();
  // }, []);

  useEffect(() => {
    const flattenedData = exportData.map(flattenObject); // Process each entry in exportData
    setProcessedData(flattenedData); // Assume you have a setState for processedData
  }, [exportData]);

  const fetchExportData = async () => {
    if (!loading) {
      setLoading(true);
      setDownloadReady(false);
      setErrorMessage('');

      const params = {
        report_date: moment(date).format('YYYY-MM'),
        status: status?.value,
        page: 1,
        limit: 10000,
        cycle: selectedCycle?.value,
        integration: selectedIntegration?.value,
        clients: selectedClients?.value,
      };

      try {
        const res = await api.Performance.getPerformance(params);
        if (!res.error && res.data.length > 0) {
          // Check if data is non-empty
          setExportData(res.data);
          setDownloadReady(true); // Enable download only if data is non-empty
        } else {
          // Handle no data or error case
          setDownloadReady(false); // Ensure download is disabled
          setErrorMessage(
            'No data available to download. Please choose a different date and try again.'
          );
        }
      } catch (error) {
        console.error('An error occurred:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  function flattenObject(obj: any) {
    const name =
      obj !== undefined && obj?.health_status?.length > 0
        ? obj?.health_status[0]?.name
        : '';
    let flatObject = {
      name: obj?.name,
      domain: obj?.domain,
      refdomains: obj?.ahrefs.refdomains,
      account_manager: obj?.account_manager,
      consultant: obj?.consultant,
      status: obj?.status,
      top_1_keywords_reach: obj?.ahrefs?.top_1_keywords_reach,
      top_3_keywords_reach: obj?.ahrefs?.top_3_keywords_reach,
      top_10_keywords_reach: obj?.ahrefs?.top_10_keywords_reach,
      calculated_fields: obj?.ahrefs?.calculated_fields,
      cycle: obj?.cycle,
      created_at: obj?.created_at,
      new_users: obj?.ga4?.new_users,
      organic_conversions: obj?.ga4?.organic_conversions,
      organic_new_users: obj?.ga4?.organic_new_users,
      organic_revenue: obj?.ga4?.organic_revenue,
      gad: obj?.ga4?.gad,
      clicks: obj?.gsc?.clicks,
      impressions: obj?.gsc?.impressions,
      hsName: name,
      manager: obj?.manager,
      number_of_positive: obj?.number_of_positive,
      performance: obj?.performance,
      report_date: obj?.report_date,
      revenue: obj?.revenue,
      service: obj?.service,
      start_date: obj?.start_date,
      teamwork_overdue_tasks: obj?.teamwork?.teamwork_overdue_tasks,
    };
    return flatObject;
  }

  useEffect(() => {
    const flattenedData = exportData.map((item) => flattenObject(item)); // Correctly process each entry
    setProcessedData(flattenedData); // Update the state with processed data
  }, [exportData]);

  useEffect(() => {
    if (processedData.length > 0) {
      // Generate headers from the keys of the first object in processedData
      const headersFromData = Object.keys(processedData[0]).map((key) => ({
        label: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '), // Optional: Format the key to capitalize the first letter
        key: key,
      }));
      setHeaders(headersFromData);
    }
  }, [processedData]);
  return (
    <div className="flex w-[100%] h-[100%] justify-center pt-[10%]">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="flex flex-col h-[350px] w-[850px] rounded-lg bg-white p-5 dark:bg-gray-800"
      >
        <div className="flex justify-between items-center h-[20px] mb-4">
          <span>
            <Icon name="AlignLeft" />
          </span>
          <span onClick={closeModal} className="cursor-pointer text-lg">
            <Icon name="Xclose" />
          </span>
        </div>
        {selectDate && (
          <div className="flex justify-between gap-4 items-center">
            <div>
              <DatePicker
                selected={moment(date, 'YYYY-MM').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    setDate(date);
                    setDownloadReady(false);
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[40px] w-40 border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-md text-[14px] text-[#525252]"
              />
            </div>
            <div>
              <ReactSelect
                options={cycleOptions}
                value={selectedCycle}
                placeholder="All cycles"
                onChange={(e) => {
                  setSelectedCycle(e);
                  setDownloadReady(false);
                }}
                className='w-36'
              />
            </div>
            <div>
              <ReactSelect
                options={clientsOptions}
                value={selectedClients}
                placeholder="All Clients"
                onChange={(e) => {
                  setSelectedClients(e);
                  setDownloadReady(false);
                }}
                className='w-36'
              />
            </div>
            <div>
              <ReactSelect
                options={integrationOptions}
                value={selectedIntegration}
                placeholder="Integrated"
                onChange={(e) => {
                  setSelectedIntegration(e);
                  setDownloadReady(false);
                }}
                className='w-[210px]'
              />
            </div>
            <div>
              <ReactSelect
                options={statusOptions}
                onChange={(e) => {
                  setStatus(e);
                  setDownloadReady(false);
                }}
                value={status}
                placeholder="Select Status"
                className='w-28'
              />
            </div>
          </div>
        )}

        <div className="flex justify-between gap-2 mt-4">
          {!downloadReady ? (
            <button
              className={`py-2 px-4 mt-4 bg-white hover:bg-blue-500 hover:text-white transition-opacity text-blue-500 border-blue-500 border rounded-md w-full text-center `}
              onClick={() => fetchExportData()}
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Get Data'}
            </button>
          ) : (
            <CSVLink
              data={processedData}
              headers={headers}
              asyncOnClick={true}
              filename={`performance-${moment(new Date()).format('YYYY-MM')}.csv`}
              className="py-2 px-4 mt-4 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full text-center"
            >
              {loading ? <Icon name="Loading" size={22} /> : 'Export'}
            </CSVLink>
          )}
        </div>
        {errorMessage.length > 0 && (
          <p className="text-red-500 mt-4 text-center">{errorMessage}</p>
        )}
      </div>
    </div>
  );
};

export default ExportModal;
