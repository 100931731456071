import { create } from 'zustand';
import { persist } from 'zustand/middleware';
const initialPerformanceVisibilityOption = {
  revenue: true,
  consultant: true,
  account_manager: true,
  performance: true,
  start_date: true,
  'ahrefs.top_1_keywords_reach': true,
  'ahrefs.top_3_keywords_reach': true,
  'ahrefs.top_10_keywords_reach': true,
  'ga4.new_users': true,
  'ga4.organic_conversions': true,
  'ga4.organic_new_users': true,
  'ga4.organic_revenue': true,
  'return.conversions': true,
  'return.ecommerce': true,
  'ahrefs.refdomains': true,
  'gsc.clicks': true,
  'gsc.impressions': true,
  'googleAds.clicks': true,
  'googleAds.conversion_rate': true,
  'googleAds.conversions': true,
  'googleAds.conversions_value': true,
  'googleAds.costPerConversion': true,
  'googleAds.cpc': true,
  'googleAds.ctr': true,
  'googleAds.impressions': true,
  'googleAds.roas': true,
  'meta.impressions': true,
  'meta.reach': true,
  'meta.frequency': true,
  'meta.cpm': true,
  'meta.link_clicks': true,
  'meta.ctr': true,
  'meta.website_leads': true,
  'meta.cpl': true,
  'meta.cvr': true,
  'meta.revenue': true,
  'meta.roas': true,
}
const useVisibilityStore = create(
  persist(
    (set) => ({
      openVisibility: false,
      setOpenVisibility: (visibility) => set({ openVisibility: visibility }),
      monthlyVisibilityOption: {
        HealthStatus: true,
        AM: true,
        Consultants: true,
        Overview: true,
        Opportunities: true,
        WorkCompleted: true,
        WorkPlanned: true,
        Performance: true,
        TrafficConversion: true,
        KeywordsRanking: true,
      },
      weeklyVisibilityOption: {
        account_manager: true,
        consultant: true,
        report_clients: true,
        'report.seven_days.impressions': true,
        'report.seven_days.clicks': true,
        'report.budget_to_date': true,
        'report.monthly_budget': true,
        'report.seven_days.roas': true,
        'report.spend_to_date': true,
        'report.budget_difference': true,
        'report.seven_days.ctr': true,
        'report.seven_days.average_cpc': true,
        'report.seven_days.cost_per_conversion': true,
        'report.seven_days.conversion_rate': true,
        'report.seven_days.conversions_value': true,
        'report.seven_days.newUsers': true,
        'report.seven_days.organic_newUsers': true,
        'report.seven_days.organic_sessions': true,
        'report.seven_days.organic_engagedSessions': true,
        'report.seven_days.organic_conversions': true,
        'report.seven_days.organic_conversions_rate': true,
        'report.seven_days.top_pages_10': true,
        'report.seven_days.top_page': true,
        'report.seven_days.top_3': true,
        'report.seven_days.refDomain': true,
        'report.seven_days.domain_rating': true,
        'report.seven_days.url_rating': true,
        conversions: true,
      },
      performanceVisibilityOption: initialPerformanceVisibilityOption,

      setVisibilityOption: (visible) =>
        set((state) => ({
          monthlyVisibilityOption: {
            ...state.monthlyVisibilityOption,
            ...visible,
          },
          weeklyVisibilityOption: {
            ...state.weeklyVisibilityOption,
            ...visible,
          },
          performanceVisibilityOption: {
            ...state.performanceVisibilityOption,
            ...visible,
          },
        })),
      resetPerfromanceVisibility: () =>
        set((state) => ({
          performanceVisibilityOption: initialPerformanceVisibilityOption,
        })),
    }),
    {
      name: 'columnVisibility-store',
    }
  )
);

export default useVisibilityStore;
