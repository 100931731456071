import moment from 'moment';

export const getStatusMetric = (status) => {
  switch (status) {
    case 'organic_sessions':
      return 'Organic Sessions';
    case 'organic_conversions':
      return 'Organic Conversions';
    case 'organic_revenue':
      return 'Organic Revenue';
    case 'totalRevenue':
      return 'Total Revenue';
    case 'transactions':
      return 'Transactions';
    case 'sessions':
      return 'Sessions (All Channels)';
    case 'engagedSessions':
      return 'Engaged Sessions';
    case 'organic_engagedSessions':
      return 'Organic Engaged Sessions';
    case 'newUsers':
      return 'New Users';
    case 'organic_newUsers':
      return 'New Users (Organic)';
    case 'sessionConversionRate':
      return 'Session Conversion Rate';
    case 'landingPage_sessions':
      return 'Landing Page (All Channels) - Sessions';
    case 'landingPage_conversions':
      return 'Landing Page (All Channels) - Conversions';
    case 'landingPage_organic_sessions':
      return 'Organic Landing Page - Sessions';
    case 'landingPage_organic_conversions':
      return 'Organic Landing Page - Conversions';
    case 'clicks':
      return 'Clicks';
    case 'conversion_rate':
      return 'Conversion Rate';
    case 'conversions':
      return 'Conversions';
    case 'conversions_value':
      return 'Conversion Value';
    case 'cost':
      return 'Cost';
    case 'cpc':
      return 'Cost Per Click';
    case 'cost_per_conversion':
      return 'Cost Per Conversion';
    case 'ctr':
      return 'Click-Through Rate';
    case 'impressions':
      return 'Impressions';
    case 'roas':
      return 'Return on Ad Spend';
    case 'position':
      return 'Positions';
    case 'spend':
      return 'Ad Spend';
    case 'revenue':
      return 'Revenue';
    case 'reach':
      return 'Reach';
    case 'link_clicks':
      return 'Link Clicks';
    case 'website_purchases':
      return 'Purchases';
    case 'aov':
      return 'Average Order Value (AOV)';
    case 'cps':
      return 'Cost Per Sale (CPS)';
    case 'refdomains':
      return 'Domain Referring';
    case 'domain_rating':
      return 'Domain Rating';
    case 'Adwords Traffic':
      return 'Estimated Traffic';
    case 'Organic Traffic':
      return 'Estimated Organic Traffic Value';
    case 'Spend':
      return 'Cost';
    case 'ConversionRate':
      return 'Conversion Rate';
    case 'ReturnOnAdSpend':
      return 'Return on Ad Spend';
    case 'costPerConversion':
      return 'Cost Per Conversion';
    default:
      return capitalized(status);
  }
};

export const capitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getStatus = (status) => {
  switch (status) {
    case 'at_risk':
    case 'Risk':
      return 'At Risk';
    case 'behind':
      return 'Behind';
    case 'on_track':
    case 'Tracked':
    case 'started':
      return 'On Track';
  }
};

export const statuses = [
  {
    label: 'On Track',
    value: 'on_track',
  },
  {
    label: 'At Risk',
    value: 'at_risk',
  },
  {
    label: 'Behind',
    value: 'behind',
  },
  {
    label: 'Not Started',
    value: 'not_started',
  },
  {
    label: 'Achieved',
    value: 'achieved',
  },
];

export const metrics = [
  {
    label: 'Organic Sessions',
    value: 'organic_sessions',
  },
  {
    label: 'Organic Conversions',
    value: 'organic_conversions',
  },
  {
    label: 'Organic Revenue',
    value: 'organic_revenue',
  },
  {
    label: 'Total Revenue',
    value: 'totalRevenue',
  },
  {
    label: 'Clicks',
    value: 'clicks',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
];

export const metricSourceOptions = [
  {
    label: 'All Sources',
    value: null,
  },
  {
    label: 'Ahrefs',
    value: 'ahrefs',
  },
  {
    label: 'GA4',
    value: 'ga4',
  },
  {
    label: 'Google Ads',
    value: 'googleAds',
  },
  {
    label: 'GSC',
    value: 'gsc',
  },
  {
    label: 'Meta',
    value: 'meta',
  },
  {
    label: 'Microsoft Ads',
    value: 'bing',
  },
  {
    label: 'Semrush',
    value: 'semrush',
  },
];

// Utility function to get dependent options with label and value
export const getMetricSourceOptions = (selectedSource: string) => {
  const dependentOptions = {
    ahrefs: [
      { label: 'All', value: '' },
      { label: 'Domain Referring', value: 'refdomains' },
      { label: 'Domain Rating', value: 'domain_rating' },
    ],
    ga4: [
      { label: 'All', value: '' },
      { label: 'Organic Sessions', value: 'organic_sessions' },
      { label: 'Organic Conversions', value: 'organic_conversions' },
      { label: 'Organic Revenue', value: 'organic_totalRevenue' },
      { label: 'Total Revenue', value: 'totalRevenue' },
      { label: 'Transactions', value: 'transactions' },
      { label: 'Sessions (All Channels)', value: 'sessions' },
      { label: 'Engaged Sessions', value: 'engagedSessions' },
      { label: 'Organic Engaged Sessions', value: 'organic_engagedSessions' },
      { label: 'New users', value: 'newUsers' },
      { label: 'New Users (Organic)', value: 'organic_newUsers' },
      { label: 'Session Conversion Rate', value: 'sessionConversionRate' },
      {
        label: 'Landing Page (All Channels) - Sessions',
        value: 'landingPage_sessions',
      },
      {
        label: 'Landing Page (All Channels) - Conversions',
        value: 'landingPage_conversions',
      },
      {
        label: 'Organic Landing Page - Sessions',
        value: 'landingPage_organic_sessions',
      },
      {
        label: 'Organic Landing Page - Conversions',
        value: 'landingPage_organic_conversions',
      },
      { label: 'Total Conversions', value: '' },
      { label: 'Session Conversion Rate', value: '' },
      { label: 'Organic Conversions', value: '' },
      { label: 'Organic Conversions Rate', value: '' },
    ],
    googleAds: [
      { label: 'All', value: '' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'Conversion Rate`', value: 'conversion_rate' },
      { label: 'Conversions', value: 'conversions' },
      { label: 'Conversion Value', value: 'conversions_value' },
      { label: 'Cost', value: 'cost' },
      { label: 'Cost Per Click', value: 'cpc' },
      { label: 'Cost Per Conversions', value: 'cost_per_conversion' },
      { label: 'Click-Through Rate', value: 'ctr' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Return on Ad Spend', value: 'roas' },
    ],
    gsc: [
      { label: 'All', value: '' },
      { label: 'Clicks', value: 'clicks' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'CTR', value: 'ctr' },
      { label: 'Positions', value: 'position' },
    ],
    meta: [
      { label: 'All', value: '' },
      { label: 'Ad Spend', value: 'spend' },
      { label: 'Revenue', value: 'revenue' },
      { label: 'ROAS', value: 'roas' },
      { label: 'Impressions', value: 'impressions' },
      { label: 'Reach', value: 'reach' },
      { label: 'CPM', value: 'cpm' },
      { label: 'Link Clicks', value: 'link_clicks' },
      { label: 'CTR', value: 'ctr' },
      { label: 'CPC', value: 'cpc' },
      { label: 'Purchases', value: 'website_purchases' },
      { label: 'Average Order Value (AOV)', value: 'aov' },
      { label: 'Cost Per Sale (CPS)', value: 'cps' },
      { label: 'Revenue by Campaign', value: '' },
    ],
    bing: [
      { label: 'All', value: '' },
      { label: 'Clicks', value: 'Clicks' },
      { label: 'Conversion Rate', value: 'ConversionRate' },
      { label: 'Conversions', value: 'Conversions' },
      { label: 'Conversion Value', value: '' },
      { label: 'Cost', value: 'Spend' },
      { label: 'Cost Per Click', value: 'cpc' },
      { label: 'Cost Per Conversions', value: 'costPerConversion' },
      { label: 'Click-Through Rate', value: 'Ctr' },
      { label: 'Impressions', value: 'Impressions' },
      { label: 'Return on Ad Spend', value: 'ReturnOnAdSpend' },
    ],
    semrush: [
      { label: 'All', value: '' },
      { label: 'Estimated Traffic', value: 'Adwords Traffic' },
      { label: 'Estimated Organic Traffic Value', value: 'Organic Traffic' },
    ],
  };

  return dependentOptions[selectedSource] || [];
};

export const getMetricSourceIcon = (key: string) => {
  const keywordMappings = {
    ahrefs: {
      label: 'Ahrefs',
      value: 'Ahref',
    },
    ga4: {
      label: 'GA4',
      value: 'googleAnalytics',
    },
    googleAds: {
      label: 'Google Ads',
      value: 'googleAd',
    },
    gsc: {
      label: 'GSC',
      value: 'googleSearch',
    },
    meta: {
      label: 'Meta',
      value: 'Meta',
    },
    bing: {
      label: 'Microsoft Ads',
      value: 'BingAnalyticsTab',
    },
    semrush: {
      label: 'Semrush',
      value: 'semrush',
    },
  };

  return (
    keywordMappings[key] || {
      label: 'Unknown',
      value: 'unknown',
    }
  );
};

export const frequency = [
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Quarterly',
    value: 'quarterly',
  },
];

export const getCurrentPeriodTarget = (dataArray) => {
  const today = moment(); // Get today's date using moment

  // Helper function to determine the current quarter
  const getQuarter = (date) => Math.floor(date.month() / 3) + 1; // Q1: Jan-Mar, Q2: Apr-Jun, etc.

  // Helper function to check if the date is in the current week
  const isInCurrentWeek = (entryDate) => {
    const startOfWeek = today.clone().startOf('week'); // Start of the current week (Sunday)
    const endOfWeek = today.clone().endOf('week'); // End of the current week (Saturday)
    return entryDate.isBetween(startOfWeek, endOfWeek, null, '[]'); // Inclusive range
  };

  // Find the correct entry based on the type
  const currentPeriodData = dataArray.find((entry) => {
    const entryDate = moment(entry.targetDate); // Convert targetDate to a moment object

    if (entry.type === 'monthly') {
      // Check if the entry is for the current month and year
      return (
        entryDate.isSame(today, 'month') && entryDate.isSame(today, 'year')
      );
    }

    if (entry.type === 'weekly') {
      // Check if the entry's targetDate falls within the current week
      return isInCurrentWeek(entryDate);
    }

    if (entry.type === 'quarterly') {
      // Check if the entry is for the current quarter and year
      return (
        getQuarter(entryDate) === getQuarter(today) &&
        entryDate.isSame(today, 'year')
      );
    }

    return false;
  });

  // If no matching period is found, return the last entry in the array
  return currentPeriodData || dataArray[dataArray.length - 1];
};
