import React, { useEffect, useState } from 'react';
import {
  Card,
  Grid,
  Col,
  LineChart,
  BarChart,
  Tab,
  TabGroup,
  TabList,
  BarList,
  TabPanels,
  TabPanel,
  DonutChart,
  Legend,
} from '@tremor/react';
import ReactSelect from '../../components/ReactSelect';
import SearchBar from '../../components/Search';
import { getServiceLabel } from '../../utils/common';
import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import usePerformanceStore from '../../store/usePerformanceStore';
import UserSearch from '../../components/PerformanceInsights/UserSearchbar';
import ClientSearch from '../../components/PerformanceInsights/ClientSearchBar';
import { formatNumber, formatToCurrency } from '../../utils/common';

const dataLineFormatter = (number: number) =>
  Intl.NumberFormat('us').format(number).toString();

const PerformanceInsights = () => {
  const {
    fetchInsightsOverall,
    insightsOverall,
    fetchInsightsService,
    insightsService,
    fetchInsightsStatus,
    insightsStatus,
    fetchInsightsSummary,
    insightsSummary,
  } = usePerformanceStore((state) => state);

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  // const [insightsStatusService, setInsightsStatusService] = useState<{
  //   label: string;
  //   value: string;
  // }>({
  //   label: 'Google SEO',
  //   value: 'seo_google',
  // });

  const [insightsStatusService, setInsightsStatusService] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [topPerformance, setTopPerformance] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Google SEO',
    value: 'seo_google',
  });

  const [underPerformance, setUnderPerformance] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Google SEO',
    value: 'seo_google',
  });

  useEffect(() => {
    fetchInsightsOverall();
    fetchInsightsSummary();
  }, []);

  console.log(insightsSummary);

  useEffect(() => {
    handleFetchInsightsService();
  }, [selectedClientId]);

  useEffect(() => {
    handleFetchInsightstatus();
  }, [selectedUserId, insightsStatusService]);

  const handleFetchInsightsService = () => {
    const payload = {
      clientId: selectedClientId,
    };
    fetchInsightsService(payload);
  };

  const handleFetchInsightstatus = () => {
    const payload = {
      userId: selectedUserId,
      service: insightsStatusService?.value,
    };
    fetchInsightsStatus(payload);
  };

  const statusData = insightsStatus?.dates?.map((date, index) => {
    const { green = {}, amber = {}, red = {} } = insightsStatus.values[index];

    // Calculate sums for Lead Gen and Ecommerce
    const leadGen =
      (green['Lead Gen'] || 0) +
      (amber['Lead Gen'] || 0) +
      (red['Lead Gen'] || 0);
    const ecommerce =
      (green['Ecommerce'] || 0) +
      (amber['Ecommerce'] || 0) +
      (red['Ecommerce'] || 0);

    return {
      date,
      Green: (green['Lead Gen'] || 0) + (green['Ecommerce'] || 0),
      green: {
        'Lead Gen': green['Lead Gen'] || 0,
        Ecommerce: green['Ecommerce'] || 0,
      },
      Amber: (amber['Lead Gen'] || 0) + (amber['Ecommerce'] || 0),
      amber: {
        'Lead Gen': amber['Lead Gen'] || 0,
        Ecommerce: amber['Ecommerce'] || 0,
      },
      red: {
        'Lead Gen': red['Lead Gen'] || 0,
        Ecommerce: red['Ecommerce'] || 0,
      },
      Red: (red['Lead Gen'] || 0) + (red['Ecommerce'] || 0),
    };
  });

  const serviceData = insightsService?.dates?.map((date, index) => {
    const {
      seo_google = 0,
      ppc_google = 0,
      social_meta = 0,
    } = insightsService?.values[index];
    return {
      date: date,
      'Google SEO': seo_google,
      'Google Ads': ppc_google,
      Meta: social_meta,
    };
  });

  const leadGenTopData =
    insightsOverall?.[`${topPerformance?.value}`]?.['Lead Gen']?.top;

  const ecommerceTopData =
    insightsOverall?.[`${topPerformance?.value}`]?.['Ecommerce']?.top;

  const leadGenUnderPerformanceData =
    insightsOverall?.[`${underPerformance?.value}`]?.['Lead Gen']?.low;

  const ecommerceUnderPerformanceData =
    insightsOverall?.[`${underPerformance?.value}`]?.['Ecommerce']?.low;

  const dataFormatter = (
    number: number | string,
    hasDollar?: boolean,
    hasPercent?: boolean
  ) => {
    return hasDollar
      ? formatToCurrency(number)
      : formatNumber(number, hasPercent);
  };

  type CustomTooltipTypeScatter = {
    payload: any;
    active: boolean | undefined;
    label: any;
    hasPercent?: boolean;
  };

  const customTooltip = (props: CustomTooltipTypeScatter) => {
    const { payload, active, label } = props;
    if (!active || !payload || payload.length === 0) return null;

    const payloadItem = payload[0];
    const amberData = payloadItem.payload.amber;
    const greenData = payloadItem.payload.green;
    const redData = payloadItem.payload.red;

    return (
      <div className="w-fit rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-dark-tremor-background dark:ring-dark-tremor-ring dark:shadow-dark-tremor-card dark:border-gray-400 p-2 text-tremor-default shadow-tremor-dropdown">
        <div className="flex flex-1 space-x-2.5">
          <div className="w-full">
            <p className="mb-2 font-medium text-tremor-content-emphasis dark:text-white">
              {label}
            </p>
            <div className={`flex flex-col space-y-2 font-bold`}>
              <div className="flex items-center text-[10px]">
                <div className="w-2 h-2 rounded-full bg-[#22C55E]" />
                {/* <div className="ml-1 text-xs dark:text-white">
                  {greenData?.LeadGen || 0 + greenData?.Ecommerce || 0}
                </div> */}
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {greenData?.LeadGen || 0}
                  </span>
                  <span className="ml-1 rounded  text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {greenData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#F59E0B]" />
                {/* <div className="ml-1 text-xs dark:text-white">
                  {amberData?.LeadGen || 0 + amberData?.Ecommerce || 0}
                </div> */}
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {amberData?.LeadGen || 0}
                  </span>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {amberData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
              <div className="flex items-center text-xs">
                <div className="w-2 h-2 rounded-full bg-[#F43F5E]" />
                {/* <div className="ml-1 text-xs dark:text-white">
                  {redData?.LeadGen || 0 + redData?.Ecommerce || 0}
                </div> */}
                <div>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Lead Gen</span>
                    {redData?.LeadGen || 0}
                  </span>
                  <span className="ml-1 rounded text-[14px] px-1 dark:text-white font-[500]">
                    <span className="border-b mr-3">Ecommerce</span>
                    {redData?.Ecommerce || 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="pl-10 pr-3 py-4">
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={1}
        numItemsLg={3}
        className="gap-4"
      >
        <Col numColSpanLg={2}>
          <Card>
            <Card>
              <Grid
                className="gap-4 mb-4"
                numItems={1}
                numItemsSm={1}
                numItemsMd={3}
                numItemsLg={3}
              >
                <Col numColSpanLg={2}>
                  <UserSearch
                    setSelectedUserId={setSelectedUserId}
                    selectedUserId={selectedUserId}
                  />
                </Col>
                <Col numColSpanLg={1}>
                  <ReactSelect
                    value={insightsStatusService}
                    options={[
                      { value: '', label: 'All Service' },
                      { value: 'seo_google', label: 'Google SEO' },
                      { value: 'ppc_google', label: 'Google Ads' },
                      { value: 'social_meta', label: 'Meta' },
                    ]}
                    handleOnChange={(selectedOption: any) => {
                      setInsightsStatusService(selectedOption);
                    }}
                    placeholder="All Service"
                  />
                </Col>
              </Grid>
              {insightsStatus.loading ? (
                <div className="w-full min-h-[320px] h-full flex justify-center items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <LineChart
                  className="h-80"
                  data={statusData}
                  index="date"
                  categories={['Green', 'Amber', 'Red']}
                  colors={['green', 'amber', 'rose']}
                  valueFormatter={dataLineFormatter}
                  startEndOnly
                  showAnimation
                  yAxisWidth={30}
                  customTooltip={customTooltip}
                  onValueChange={(v) => console.log(v)}
                />
              )}
            </Card>
            <Grid
              numItems={1}
              numItemsSm={1}
              numItemsMd={1}
              numItemsLg={4}
              className="gap-4 my-8"
            >
              <Col>
                <Card className="p-[16px]">
                  <div className="text-[16px] text-[#101828] font-[600]">
                    All Clients
                  </div>
                  {insightsSummary?.loading ? (
                    <div className="h-[60px] w-full flex justify-center item-center mt-4">
                      <div>
                        <Icon name="loading" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="text-[24px] text-[#101828] font-[600] mb-4">
                        {insightsSummary?.all}
                      </div>
                      <div className="text-[14px] font-[500] text-[#475467]">
                        <span className="text-[#FF0000]">
                          {insightsSummary?.all_missing}
                        </span>{' '}
                        Missing Integ.
                      </div>
                    </>
                  )}
                </Card>
              </Col>
              <Col>
                <Card className="p-[16px]">
                  <div className="text-[16px] text-[#101828] font-[600]">
                    Google SEO
                  </div>
                  {insightsSummary?.loading ? (
                    <div className="h-[60px] w-full flex justify-center item-center mt-4">
                      <div>
                        <Icon name="loading" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="text-[24px] text-[#101828] font-[600] mb-4">
                        {insightsSummary?.seo}
                      </div>
                      <div className="text-[14px] font-[500] text-[#475467]">
                        <span className="text-[#FF0000]">
                          {insightsSummary?.seo_missing}
                        </span>{' '}
                        Missing Integ.
                      </div>
                    </>
                  )}
                </Card>
              </Col>
              <Col>
                <Card className="p-[16px]">
                  <div className="text-[16px] text-[#101828] font-[600]">
                    Google Ads
                  </div>
                  {insightsSummary?.loading ? (
                    <div className="h-[60px] w-full flex justify-center item-center mt-4">
                      <div>
                        <Icon name="loading" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="text-[24px] text-[#101828] font-[600] mb-4">
                        {insightsSummary?.ppc}
                      </div>
                      <div className="text-[14px] font-[500] text-[#475467]">
                        <span className="text-[#FF0000]">
                          {insightsSummary?.ppc_missing}
                        </span>{' '}
                        Missing Integ.
                      </div>
                    </>
                  )}
                </Card>
              </Col>
              <Col>
                <Card className="p-[16px]">
                  <div className="text-[16px] text-[#101828] font-[600]">
                    Meta
                  </div>
                  {insightsSummary?.loading ? (
                    <div className="h-[60px] w-full flex justify-center item-center mt-4">
                      <div>
                        <Icon name="loading" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="text-[24px] text-[#101828] font-[600] mb-4">
                        {insightsSummary?.meta}
                      </div>
                      <div className="text-[14px] font-[500] text-[#475467]">
                        <span className="text-[#FF0000]">
                          {insightsSummary?.meta_missing}
                        </span>{' '}
                        Missing Integ.
                      </div>
                    </>
                  )}
                </Card>
              </Col>
            </Grid>
            <Card>
              <div className="mb-4">
                <ClientSearch
                  setSelectedClientId={setSelectedClientId}
                  selectedClientId={selectedClientId}
                />
              </div>
              {insightsService.loading ? (
                <div className="w-full min-h-[320px] h-full flex justify-center items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <BarChart
                  className=""
                  data={serviceData}
                  showAnimation
                  startEndOnly
                  maxValue={100}
                  minValue={0}
                  index="date"
                  categories={['Google SEO', 'Google Ads', 'Meta']}
                  colors={['amber', '#000', '#0029FF', '#009EFF']}
                  valueFormatter={(value) => dataFormatter(value, false, true)}
                  yAxisWidth={48}
                />
              )}
            </Card>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card className="p-[24px] mb-8 dark:bg-[#222732]">
              <div className="font-[700] text-[18px] dark:text-white">
                Top Performance
              </div>
              <div className="mt-4">
                <ReactSelect
                  value={topPerformance}
                  options={[
                    { value: 'seo_google', label: 'Google SEO' },
                    { value: 'ppc_google', label: 'Google Ads' },
                    { value: 'social_meta', label: 'Meta' },
                  ]}
                  handleOnChange={(selectedOption: any) => {
                    setTopPerformance(selectedOption);
                  }}
                  placeholder="All Services"
                />
              </div>
              {insightsOverall?.loading ? (
                <div className="w-full min-h-[200px] h-full flex justify-center  items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <TabGroup>
                  <TabList variant="line">
                    <Tab value="1">Lead Gen</Tab>
                    <Tab value="2">Ecommerce</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {leadGenTopData && leadGenTopData.length > 0 ? (
                        leadGenTopData.map((data, index) => (
                          <div
                            key={index}
                            className="border-b flex w-full justify-between items-center"
                          >
                            <div className="w-[8vw] py-2">
                              <p
                                className="font-[600] text-sm dark:text-white truncate"
                                title={data.name}
                              >
                                {data.name}
                              </p>
                              <p className="font-light text-[#6B7280] text-sm">
                                {getServiceLabel(data.service)?.label}
                              </p>
                            </div>
                            <div className="text-[#3CCB7F] text-sm">
                              {data.performance?.score}%
                            </div>
                            <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                              {data.category_type}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-full text-center pt-20 text-gray-500">
                          No data
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {ecommerceTopData && ecommerceTopData.length > 0 ? (
                        ecommerceTopData.map((data, index) => (
                          <div
                            key={index}
                            className="border-b flex w-full justify-between items-center"
                          >
                            <div className="w-[8vw] py-2">
                              <p
                                className="font-[600] text-sm dark:text-white truncate"
                                title={data.name}
                              >
                                {data.name}
                              </p>
                              <p className="font-light text-[#6B7280] text-sm">
                                {getServiceLabel(data.service)?.label}
                              </p>
                            </div>
                            <div className="text-[#3CCB7F] text-sm">
                              {data.performance?.score}%
                            </div>
                            <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                              {data.category_type}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-full text-center pt-20 text-gray-500">
                          No data
                        </div>
                      )}
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              )}
            </Card>
            <Card className="p-[24px] dark:bg-[#222732]">
              <div className="font-[700] text-[18px] dark:text-white">
                Under Performance
              </div>
              <div className="mt-4">
                <ReactSelect
                  value={underPerformance}
                  options={[
                    { value: 'seo_google', label: 'Google SEO' },
                    { value: 'ppc_google', label: 'Google Ads' },
                    { value: 'social_meta', label: 'Meta' },
                  ]}
                  handleOnChange={(selectedOption: any) => {
                    setUnderPerformance(selectedOption);
                  }}
                  placeholder="All Services"
                />
              </div>
              {insightsOverall?.loading ? (
                <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <TabGroup>
                  <TabList variant="line">
                    <Tab value="1">Lead Gen</Tab>
                    <Tab value="2">Ecommerce</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {leadGenUnderPerformanceData &&
                      leadGenUnderPerformanceData.length > 0 ? (
                        leadGenUnderPerformanceData.map((data, index) => (
                          <div
                            key={index}
                            className="border-b flex w-full justify-between items-center"
                          >
                            <div className="w-[8vw] py-2">
                              <p
                                className="font-[600] text-sm dark:text-white truncate"
                                title={data.name}
                              >
                                {data.name}
                              </p>
                              <p className="font-light text-[#6B7280] text-sm">
                                {getServiceLabel(data.service)?.label}
                              </p>
                            </div>
                            <div className="text-[#3CCB7F] text-sm">
                              {data.performance?.score}%
                            </div>
                            <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                              {data.category_type}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-full text-center pt-20 text-gray-500">
                          No data
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {ecommerceUnderPerformanceData &&
                      ecommerceUnderPerformanceData.length > 0 ? (
                        ecommerceUnderPerformanceData.map((data, index) => (
                          <div
                            key={index}
                            className="border-b flex w-full justify-between items-center"
                          >
                            <div className="w-[8vw] py-2">
                              <p
                                className="font-[600] text-sm dark:text-white truncate"
                                title={data.name}
                              >
                                {data.name}
                              </p>
                              <p className="font-light text-[#6B7280] text-sm">
                                {getServiceLabel(data.service)?.label}
                              </p>
                            </div>
                            <div className="text-[#3CCB7F] text-sm">
                              {data.performance?.score}%
                            </div>
                            <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                              {data.category_type}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="w-full text-center pt-20 text-gray-500">
                          No data
                        </div>
                      )}
                    </TabPanel>
                  </TabPanels>
                </TabGroup>
              )}
            </Card>
          </Card>
        </Col>
      </Grid>

      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={4}
        className="gap-4 mt-4"
      >
        <Col></Col>
        <Col></Col>
        {/* <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Lead Gen
            </div>
            <div className={'mt-4'}>
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <BarList
                data={leadGenData}
                className="mx-auto max-w-sm mt-4"
                showAnimation
                color="teal"
              />
            )}
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Ecommerce
            </div>
            <div className="mt-4">
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <>
                <div className="flex justify-center mt-4">
                  <DonutChart
                    data={ecommerceData}
                    variant="pie"
                    valueFormatter={dataFormatter}
                    onValueChange={(v) => console.log(v)}
                    showAnimation
                  />
                </div>
                <div className="mt-4 w-full flex justify-center">
                  <Legend
                    categories={['Google SEO', 'Google Ads', 'Meta Ads']}
                    colors={['blue', 'cyan', 'indigo', 'violet', 'fuchsia']}
                    className="max-w-[14vw] text-center"
                  />
                </div>
              </>
            )}
          </Card>
        </Col> */}
      </Grid>
    </div>
  );
};

export default PerformanceInsights;
